import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './../common.css';
import {Modal} from 'react-bootstrap';
import axios from "axios";
import {store} from "react-notifications-component";
import Constants from "../constants";
import {withRouter} from "react-router-dom";

class DialogDeleteProject extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: props.show,
            project: props.project,
            input: ''
        }

        this.hide = this.hide.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    hide() {
        this.props.onClose();
    }

    deleteProject() {
        const notificationError = {
            title: 'Error',
            message: 'Error occurred while deleting project "' + this.state.project.title + '"',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }

        const url = Constants.BASE_URL + 'projects/' + this.state.project.id + '/delete';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        axios.delete(url, config)
            .then(response => {
                console.log(response);
                if (response.data.status === 'success') {
                    this.props.history.replace('/projects');
                } else {
                    store.addNotification(notificationError);
                }
            })
            .catch(() => {
                store.addNotification(notificationError);
            });
    }

    handleInputChange(event) {
        this.setState({
            input: event.target.value
        });
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.hide}>
                <Modal.Header>
                    <Modal.Title>Create new project</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input type='text' className='form-control' placeholder='Type project title to delete'
                           onChange={this.handleInputChange}/>
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-outline-secondary' onClick={this.hide}>Cancel</button>
                    <button className='btn btn-danger' onClick={this.deleteProject}
                            disabled={this.state.input !== this.state.project.title}>Delete
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default withRouter(DialogDeleteProject);