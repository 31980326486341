import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './../common.css';
import './analytics.css';
import Constants from "../constants";
import axios from "axios";
import {store} from "react-notifications-component";

class Request extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestId: props.requestId,
            path: props.path,
            count: props.count
        }
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleRemove() {
        this.props.onRemove(this.state.requestId);
    }

    render() {
        return (
            <div className='flex'>
                <div className='flex request-container'>
                    {this.state.path}
                    <div className='flex vertical-center'>
                        <i className="fas fa-eye"/>&emsp;{this.state.count}
                    </div>
                </div>
                &emsp;
                <button className='btn btn-outline-danger' onClick={this.handleRemove}>Remove</button>
            </div>
        );
    }
}

class RequestsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            isLoaded: false,
            error: null
        }

        this.handleRemoveRequest = this.handleRemoveRequest.bind(this);
    }

    componentDidMount() {
        this.loadInvalidRequests();
    }

    loadInvalidRequests() {
        const url = Constants.BASE_URL + 'invalid-requests';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        axios.get(url, config)
            .then(response => {
                if (response.data.status === 'success') {
                    this.setState({
                        requests: response.data.requests,
                        isLoaded: true
                    });
                } else {
                    this.setState({
                        error: "Unexpected error.",
                        isLoaded: true
                    });
                }
            })
            .catch(error => {
                this.setState({
                    error: error.message,
                    isLoaded: true
                })
            })
    }

    handleRemoveRequest(id) {
        const notificationError = {
            title: 'Error',
            message: 'Cannot delete request. Try again.',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }
        const notificationSuccess = {
            ...notificationError,
            title: 'Success',
            message: 'Request successfully deleted',
            type: 'success'
        }

        const url = Constants.BASE_URL + 'invalid-requests/' + id + '/delete';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        axios.delete(url, config)
            .then(response => {
                if (response.data.status === 'success') {
                    store.addNotification(notificationSuccess);
                    this.setState({
                        isLoaded: false
                    }, () => {
                        this.loadInvalidRequests();
                    });
                } else {
                    store.addNotification(notificationError);
                }
            })
            .catch(() => {
                store.addNotification(notificationError);
            })
    }

    render() {
        if (this.state.error) {
            return (
                <div>{this.state.error}</div>
            );
        } else {
            if (!this.state.isLoaded) {
                return (
                    <div>Loading...</div>
                )
            } else {
                return (
                    <div>
                        <h1>Analytics</h1>
                        <h5>Requests with no project found</h5>

                        <div className='card requests-list-container'>
                            <ul className='list-group'>
                                {this.state.requests.map(request => (
                                    <li className='list-group-item request-item' key={request.id}>
                                        <Request key={request.id} requestId={request.id} path={request.path}
                                                 count={request.views} onRemove={this.handleRemoveRequest}/>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                );
            }
        }
    }
}

function Analytics() {
    return (
        <RequestsPanel/>
    );
}

export default Analytics