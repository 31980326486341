import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './../common.css';
import AppsPanel from '../apppanel/apppanel.js'
import {withRouter} from "react-router-dom";
import Constants from "../constants";
import axios from "axios";
import {store} from "react-notifications-component";
import DialogDeleteProject from "../dialogs/delete";

class EditProject extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            project: null,
            isLoaded: false,
            error: null,
            showDialog: false
        }

        this.onTitleChange = this.onTitleChange.bind(this);
        this.onPathChange = this.onPathChange.bind(this);
        this.onAppsChange = this.onAppsChange.bind(this);
        this.updateProject = this.updateProject.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
    }

    componentDidMount() {
        this.loadProjectInfo();
    }

    loadProjectInfo() {
        let id = this.props.match.params.id
        const url = Constants.BASE_URL + 'projects/' + id;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        axios.get(url, config)
            .then(response => {
                console.log(response);
                if (response.data.status === 'success') {
                    this.setState({
                        project: response.data.project,
                        isLoaded: true
                    });
                } else {
                    this.setState({
                        error: "Undefined error",
                        isLoaded: true
                    });
                }
            })
            .catch(error => {
                this.setState({
                    error: error.message,
                    isLoaded: true
                });
            })
    }

    updateProject() {
        const notificationSuccess = {
            title: 'Success',
            message: 'Project successfully updated',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }
        const notificationError = {
            ...notificationSuccess,
            title: 'Error',
            message: 'Error occurred while updating project',
            type: 'danger'
        }

        const url = Constants.BASE_URL + 'projects/' + this.state.project.id + '/update';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }

        const body = {
            'title': this.state.project.title,
            'path': this.state.project.path,
            'apps': this.state.project.apps,
        }
        axios.post(url, body, config)
            .then(response => {
                if (response.data.status === 'success') {
                    store.addNotification(notificationSuccess);
                    this.setState({
                        isLoaded: false
                    }, () => {
                        this.loadProjectInfo();
                    })
                } else {
                    store.addNotification(notificationError);
                }
            })
            .catch(() => {
                store.addNotification(notificationError);
            })
    }

    showDeleteDialog() {
        this.setState({
            showDialog: true
        });
    }

    hideDeleteDialog() {
        this.setState({
            showDialog: false
        });
    }

    onTitleChange(title) {
        let project = this.state.project;
        project.title = title;
        this.setState({
            project: project
        });
    }

    onPathChange(path) {
        let project = this.state.project;
        project.path = path;
        this.setState({
            project: project
        });
    }

    onAppsChange(apps) {
        let project = this.state.project;
        project.apps = apps;
        this.setState({
            project: project
        });
    }

    render() {

        if (this.state.error) {
            return (
                <div>{this.state.error}</div>
            );
        } else {
            if (!this.state.isLoaded) {
                return (
                    <div>Loading...</div>
                );
            } else {
                return (
                    <div>
                        <DialogDeleteProject
                            key={this.state.showDialog.toString()}
                            show={this.state.showDialog}
                            project={this.state.project}
                            onClose={this.hideDeleteDialog}
                        />
                        <AppsPanel
                            title={this.state.project.title}
                            path={this.state.project.path}
                            apps={this.state.project.apps}
                            onTitleChange={this.onTitleChange}
                            onPathChange={this.onPathChange}
                            onAppsChange={this.onAppsChange}
                            onSaveClick={this.updateProject}
                            onDeleteClick={this.showDeleteDialog}
                        />
                    </div>
                );
            }
        }
    }
}

export default withRouter(EditProject);