import React from 'react';
import './login.css';
import './../common.css';
import {Link} from "react-router-dom";
import axios from "axios";
import {withRouter} from "react-router";
import Constants from "../constants";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    handleLogin() {
        const url = Constants.BASE_URL + 'login';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        const body = {
            email: this.state.email,
            password: this.state.password
        }
        axios.post(url, body, config)
            .then(response => {
                if (response.data.status === 'success') {
                    const token = response.data.token;
                    this.props.onLogin(token);
                } else {
                    alert('Login error');
                }
            })
            .catch(error => {
                alert('Login error');
                console.log(error)
            })
    }

    render() {
        return (
            <div className='center-aligned'>
                <h1>AMS-Soft Advertisement</h1>
                <form className='form-register'>
                    <input type="text" className="form-control" placeholder="Email" onChange={this.handleEmailChange}/>
                    <input type="password" className="password form-control" placeholder="Password" onChange={this.handlePasswordChange}/>
                    <div className='center-aligned'>
                        <button
                            type='button'
                            className="button-register btn btn-primary"
                            disabled={this.state.email === '' || this.state.password === ''}
                            onClick={this.handleLogin}>Log in</button>
                    </div>
                    <div className='center-aligned login-holder'>
                        <Link className='link-login' to='/register/'>Sign up</Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(Login)