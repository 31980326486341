import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink as Link, Redirect
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './common.css';
import Projects from './projects/projects.js';
import EditProject from './edit/edit.js';
import Analytics from './analytics/analytics';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Login from "./login/login";
import Register from "./register/register";
import {withRouter} from 'react-router-dom'

class NavbarButtonsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.props.onLogout();
    }

    render() {
        return (
            <nav className="header navbar navbar-expand-lg navbar-light bg-light">
                <Link to='/' class="navbar-brand flex vertical-center">
                    <img src="/pictures/logo.jpg" width="40" height="40" className="d-inline-block align-top logo"
                         alt=""/>
                    <div>AMS-Soft</div>
                </Link>
                <div className="collapse navbar-collapse navigation">
                    <ul className="navbar-nav navigation">
                        <li className='nav-item'>
                            <Link activeClassName='button-active' to='/projects'>
                                <button className='btn btn-outline-success navbar-button'>Projects</button>
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link activeClassName='button-active' to='/analytics'>
                                <button className='btn btn-outline-success navbar-button'>Analytics</button>
                            </Link>
                        </li>
                    </ul>
                    <button className='btn btn-outline-danger navbar-button button-logout' onClick={this.handleLogout}>
                        Log out
                    </button>
                </div>
            </nav>
        )
    }

}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogin(token) {
        this.setState({
            token: token
        }, () => {
            localStorage.setItem('token', token);
        })
    }

    handleLogout() {
        this.setState({
            token: ''
        }, () => {
            localStorage.setItem('token', '');
        })
    }

    render() {
        return (
            <div>
                <ReactNotification/>
                <Router>
                    <div>
                        {this.state.token !== '' ?
                            <NavbarButtonsPanel
                                key={this.state.token}
                                token={this.state.token}
                                onLogout={this.handleLogout}/>
                            : null
                        }
                        <div className='content-table'>
                            <Switch>

                                <Route exact path='/'>
                                    {this.state.token !== '' ?
                                        <Redirect to='/projects'/>
                                        :
                                        <Redirect to='/login/'/>
                                    }
                                </Route>

                                <Route exact path='/projects'>
                                    {this.state.token !== '' ?
                                        <Projects/>
                                        :
                                        <Redirect to='/login/'/>
                                    }
                                </Route>

                                <Route path='/analytics'>
                                    {this.state.token !== '' ?
                                        <Analytics/>
                                        :
                                        <Redirect to='/login'/>
                                    }
                                </Route>

                                <Route path='/projects/:id/edit'>
                                    {this.state.token !== '' ?
                                        <EditProject/>
                                        :
                                        <Redirect to='/login'/>
                                    }
                                </Route>

                                <Route path='/login'>
                                    {this.state.token === '' ?
                                        <Login onLogin={this.handleLogin}/>
                                        :
                                        <Redirect to='/projects'/>
                                    }
                                </Route>

                                <Route path='/register'>
                                    <Register/>
                                </Route>

                            </Switch>
                        </div>
                    </div>
                </Router>
            </div>
        );
    }

}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);