import React from 'react';
import {BrowserRouter, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './projects.css';
import './../common.css';
import Constants from '../constants.js';
import axios from "axios";
import DialogCreateProject from "../dialogs/create";
import {store} from "react-notifications-component";

class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: props.projectId,
            title: props.title,
            route: props.route,
            views: props.views
        };
    }

    render() {
        const path = '/projects/' + this.state.projectId + '/edit';
        return (
            <div className='project-container flex vertical-center'>
                <div>
                    {this.state.title} | <span className='project-route'>{this.state.route}</span>
                </div>
                <div>
                    <i className="fas fa-eye"/>&emsp;{this.state.views}<Link to={path}>
                    <button className='btn btn-outline-success button-edit'>Edit</button>
                </Link>
                </div>
            </div>
        );
    }

}

class ProjectsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: props.projects
        }
    }

    render() {
        return (
            <div className='card projects-list'>
                <ul className='list-group'>
                    {this.state.projects.map((project) =>
                        <li className='list-group-item project-list-item' key={project.id}>
                            <Project key={project.id} projectId={project.id} title={project.title} route={project.path}
                                     views={project.views}/>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

class ProjectSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: props.projects,
            defaultId: props.defaultId
        }

        this.handleItemSelected = this.handleItemSelected.bind(this);
    }

    handleItemSelected(event) {
        this.props.onChange(event.target.value);
    }

    render() {
        let defaultProject = null;
        for (let i = 0; i < this.state.projects.length; i++) {
            if (this.state.projects[i].id === this.state.defaultId) {
                defaultProject = this.state.projects[i];
            }
        }
        return (
            <select className="project-selector form-select" defaultValue={defaultProject ? defaultProject.id : ''}
                    onChange={this.handleItemSelected}>
                {this.state.projects.map((project) =>
                    <option key={project.id} value={project.id}>{project.title}</option>
                )}
                {defaultProject
                    ? null
                    : <option hidden={true} key={"null"}/>
                }
            </select>
        );
    }
}

class ProjectsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            defaultProjectId: null,
            isLoaded: false,
            error: null,
            showDialog: false
        }

        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.onCreated = this.onCreated.bind(this);
        this.setDefaultProject = this.setDefaultProject.bind(this);
    }

    componentDidMount() {
        this.loadProjects();
    }

    loadProjects() {
        const url = Constants.BASE_URL + "projects";
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        axios.get(url, config)
            .then(response => {
                console.log(response);
                if (response.data.status === 'success') {
                    this.setState({
                        projects: response.data.projects,
                        defaultProjectId: response.data.default_id,
                        isLoaded: true
                    })
                } else {
                    this.setState({
                        error: "Unexpected error",
                        isLoaded: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    error: error.message,
                    isLoaded: true
                })
            })
    }

    handleOpenDialog() {
        this.setState({
            showDialog: true
        });
    }

    handleCloseDialog() {
        this.setState({
            showDialog: false
        });
    }

    onCreated() {
        this.setState({
            isLoaded: false
        }, () => {
            this.loadProjects();
        });
    }

    setDefaultProject(id) {
        const notificationError = {
            title: 'Error',
            message: 'Cannot update default project. Try again.',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }
        const notificationSuccess = {
            ...notificationError,
            title: 'Success',
            message: 'Default project successfully updated',
            type: 'success'
        }

        const url = Constants.BASE_URL + 'projects/default/set';
        const body = {
            'id': id
        }
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
        axios.post(url, body, config)
            .then(response => {
                if (response.data.status === 'success') {
                    store.addNotification(notificationSuccess);
                    this.setState({
                        defaultProjectId: id
                    });
                } else {
                    store.addNotification(notificationError);
                    this.setState({
                        isLoaded: false
                    }, () => {
                        this.loadProjects();
                    });
                }
            })
            .catch(() => {
                store.addNotification(notificationError);
                this.setState({
                    isLoaded: false
                }, () => {
                    this.loadProjects();
                });
            })
    }

    render() {

        if (this.state.error) {
            return (
                <div>
                    {this.state.error}
                </div>
            );
        } else {
            if (!this.state.isLoaded) {
                return (
                    <div>Loading...</div>
                );
            } else {
                return (
                    <div>
                        <div className='projects-table-header flex'>
                            <div className='flex vertical-center'>
                                <h1>Projects</h1>&emsp;
                                <button className='btn btn-outline-success' onClick={this.handleOpenDialog}>Create
                                    project
                                </button>
                                <DialogCreateProject
                                    key={this.state.showDialog.toString()}
                                    show={this.state.showDialog}
                                    onClose={this.handleCloseDialog}
                                    onCreate={this.onCreated}/>
                            </div>
                            <div className='flex vertical-center'>
                                <div>Default project:</div>
                                &emsp;<ProjectSelector projects={this.state.projects}
                                                       defaultId={this.state.defaultProjectId}
                                                       onChange={this.setDefaultProject}/>
                            </div>
                        </div>
                        <ProjectsList projects={this.state.projects}/>
                    </div>
                );
            }
        }
    }
}

function Projects() {
    return (
        <div>
            <ProjectsPanel/>
        </div>
    );
}

export default Projects