import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './apppanel.css';
import '../common.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DialogDeleteProject from "../dialogs/delete";

class TopPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            path: props.path
        }

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handlePathChange = this.handlePathChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    handleTitleChange(event) {
        this.setState({
            title: event.target.value
        }, () => {
            this.props.onTitleChange(this.state.title);
        });
    }

    handlePathChange(event) {
        this.setState({
            path: event.target.value
        }, () => {
            this.props.onPathChange(this.state.path);
        });
    }

    handleSaveClick() {
        this.props.onSaveClick();
    }

    handleDeleteClick() {
        this.props.onDeleteClick();
    }

    render() {
        return (
            <div className='flex vertical-center'>
                <input type='text' className='form-control input' id='input-project-title' value={this.state.title} placeholder='Project title' aria-label='Project title' onChange={this.handleTitleChange}/>
                &emsp;
                <input type='text' className='form-control input' value={this.state.path} placeholder='Slug' aria-label='Slug' onChange={this.handlePathChange}/>
                &emsp;
                <button className='btn btn-success button-save' onClick={this.handleSaveClick}>Save</button>
                &emsp;
                <button className='btn btn-danger' onClick={this.handleDeleteClick}>Delete</button>
            </div>
        );
    }
    
}

class AppItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appId: props.appId,
            title: props.title,
            path: props.path,
            image_url: props.image_url,
            visible: props.visible
        }

        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handlePathChanged = this.handlePathChanged.bind(this);
        this.handleImageUrlChanged = this.handleImageUrlChanged.bind(this);
        this.handleVisibleClick = this.handleVisibleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }

    handleVisibleClick() {
        this.setState({
            visible: !this.state.visible
        }, () => {
            this.handleChange();
        });
    }

    handleTitleChanged(event) {
        this.setState({
            title: event.target.value
        }, () => {
            this.handleChange();
        });
    }

    handlePathChanged(event) {
        this.setState({
            path: event.target.value
        }, () => {
            this.handleChange();
        });
    }

    handleImageUrlChanged(event) {
        this.setState({
            image_url: event.target.value
        }, () => {
            this.handleChange();
        });
    }

    handleRemove() {
        this.props.onRemove(this.state.appId);
    }

    handleChange() {
        this.props.onChange(
            this.state.appId,
            this.state.title,
            this.state.path,
            this.state.image_url,
            this.state.visible
        )
    }

    render() {
        var holderClass = 'project-holder';
        if (!this.state.visible) {
            holderClass = 'project-holder-invisible';
        }

        return(
            <div className={holderClass + ' card'}>
                <td>
                    <div className='flex'>
                        <img className='ads-item-img' src={this.state.image_url} />
                        <div className='app-info'>
                            <input type='text' className='form-control input' value={this.state.title} placeholder='App title' aria-label='App title' onChange={this.handleTitleChanged}/>
                            <input type='text' className='form-control input' value={this.state.path} placeholder='App url' aria-label='App url' onChange={this.handlePathChanged}/>
                            <input type='text' className='form-control input' value={this.state.image_url} placeholder='Picture url' aria-label='Picture url' onChange={this.handleImageUrlChanged}/>
                            <div className='flex input'>
                                <label className="form-check-label"><input type="checkbox" className="form-check-input" checked={this.state.visible} onChange={this.handleVisibleClick}/>&emsp;Visibility</label>
                            </div>
                        </div>
                        <div className='project-control-panel'>
                            <button className='btn btn-outline-danger button-remove' onClick={this.handleRemove}>Remove</button>
                            <div className='drag-image-holder'>
                                <img className='image-drag' src='/pictures/drag.png' width='60' height='60'/>
                            </div>
                        </div>
                    </div>
                </td>
            </div>
        );
    }
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

class AppList extends React.Component {
    constructor(props) {
        super(props);

        let maxId = 0;
        if (props.apps.length > 0) {
            for (let i = 0; i < props.apps.length; i++) {
                if (props.apps[i].id > maxId) {
                    maxId = props.apps[i].id;
                }
            }
        }

        this.state = {
            apps: props.apps,
            lastId: maxId
        }

        this.handleAddApp = this.handleAddApp.bind(this);
        this.handleRemoveApp = this.handleRemoveApp.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleAppChange = this.handleAppChange.bind(this);
    }

    handleAddApp() {
        let newId = this.state.lastId + 1;
        this.setState(prevState => ({
            lastId: newId,
            apps: [...prevState.apps, {id: newId, title: '', path: '', image_url: '', visible: true}]
        }), () => {
            this.handleAppsChanged();
        });
    }

    handleRemoveApp(id) {
        this.setState(prevState => ({
            apps: prevState.apps.filter(app => app.id !== id)
        }), () => {
            this.handleAppsChanged();
        });
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const apps = reorder(
          this.state.apps,
          result.source.index,
          result.destination.index
        );
    
        this.setState({
          apps: apps
        }, () => {
            this.handleAppsChanged();
        });
      }

    handleAppsChanged() {
        this.props.onAppsChange(this.state.apps);
    }

    handleAppChange(id, title, url, image_url, visible) {
        const apps = this.state.apps;
        for (let i = 0; i < apps.length; i++) {
            if (apps[i].id === id) {
                apps[i].title = title;
                apps[i].url = url;
                apps[i].image_url = image_url;
                apps[i].visible = visible;
            }
        }
    }

    render() {
        return (
            <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                    {(provided) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        {this.state.apps.map((app, index) => (
                            <Draggable key={app.id} draggableId={app.id.toString()} index={index}>
                            {(provided) => (
                                <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                >
                                    <AppItem
                                        key={app.id}
                                        appId={app.id}
                                        title={app.title}
                                        path={app.url}
                                        image_url={app.image_url}
                                        visible={app.visible}
                                        onRemove={this.handleRemoveApp}
                                        onChange={this.handleAppChange}
                                    />
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
                <button className='btn btn-success button-add-app' onClick={this.handleAddApp}>Add application</button>
            </div>
        );
    }
}

function AppsPanel(props) {
    return (
        <div>
            <TopPanel
                title={props.title}
                path={props.path}
                onTitleChange={(title) => props.onTitleChange(title)}
                onPathChange={(path) => props.onPathChange(path)}
                onSaveClick={() => props.onSaveClick()}
                onDeleteClick={() => props.onDeleteClick()}
            />
            <AppList
                apps={props.apps}
                onAppsChange={(apps) => props.onAppsChange(apps)}
            />
        </div>
    );
}

export default AppsPanel